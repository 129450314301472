/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { graphql, Link, useStaticQuery } from 'gatsby';
import LayoutMain from 'components/LayoutMain';
import { BigPictureSection} from 'components/sections';
import { SignUpForm } from 'components/forms';

const Signup = () => {
  const [pageTop, setPageTop] = useState(true);

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset === 0) setPageTop(true);
      else if (pageTop !== false) {
        setPageTop(false);
      }
    };
    return () => {
      window.onscroll = null;
    };
  }, [pageTop]);

  useEffect(() => {
    setPageTop(window.pageYOffset === 0);
  }, []);

  

  const images = useStaticQuery(graphql`
    {
        logo: file(relativePath: { eq: "logo.svg" }) {
            publicURL
        }
        sidePic: file(relativePath: { eq: "new_support.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 553) {
                ...GatsbyImageSharpFluid
              }
            }
          }
    }
  `);

  console.log(images);

  return (
    <LayoutMain>
      <StyledHeader className="header">
            <div className="header__logo-block">
                <Link to="/" className="header__logo" data-cy="header_logo">
                <img src={images.logo.publicURL} width="200px" height="30px" alt="logo" />
                </Link>
            </div>
            
        </StyledHeader>

      <StyledSignupPage>
        <BigPictureSection
          className="back-office-picture-section"
          imagePosition="right"
          image={{ fluid: images.sidePic.childImageSharp.fluid }}>
          <h2>Sign up and start for FREE</h2>
          <SignUpForm></SignUpForm>
        </BigPictureSection>
      </StyledSignupPage>
        
    </LayoutMain>
  );
};



const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
  position: relative;
  padding: 16px 60px;
  box-sizing: border-box;
  margin: auto;
  position: sticky;
  top: 0;
  background: white;
  border-bottom: 1px solid transparent;

  .header__nav a{text-decoration: none;
    color: #1e1e1e;padding-left:20px;}

  &.border {
    border-color: ${props => props.theme.colors.borderColor};

    .header__nav .demo-btn {
      background: ${props => props.theme.colors.primary};
      color: white;
    }
  }

  .header {
    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
    }

    

    &__menu-trigger {
      background: none;
      border: none;
      width: 32px;
      height: 32px;
    }

    &__nav {
      .demo-btn {
        margin-left: 32px;
        background: white;
        border: 1px solid ${props => props.theme.colors.borderColor};
        color: initial;
      }
      .login-btn {
        margin-left: 20px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 16px;
  }
`;

const StyledSignupPage = styled(LayoutMain)`
  max-width: 1200px;
  margin: 50px auto 0;
  padding: 0 16px;
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: 16px 0;
  }
  p,
  ul {
    font-size: 14px;
    line-height: 24px;
  }
  ul{
	 padding: 0 0 0 16px;

  }
  a {
    text-decoration: none;
	 color: #794CFB;
  }
`;

export default Signup;
